exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-clients-index-jsx": () => import("./../../../src/pages/about/clients/index.jsx" /* webpackChunkName: "component---src-pages-about-clients-index-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-about-manifesto-index-jsx": () => import("./../../../src/pages/about/manifesto/index.jsx" /* webpackChunkName: "component---src-pages-about-manifesto-index-jsx" */),
  "component---src-pages-about-process-index-jsx": () => import("./../../../src/pages/about/process/index.jsx" /* webpackChunkName: "component---src-pages-about-process-index-jsx" */),
  "component---src-pages-about-testimonial-index-jsx": () => import("./../../../src/pages/about/testimonial/index.jsx" /* webpackChunkName: "component---src-pages-about-testimonial-index-jsx" */),
  "component---src-pages-blockchain-development-jsx": () => import("./../../../src/pages/blockchain-development.jsx" /* webpackChunkName: "component---src-pages-blockchain-development-jsx" */),
  "component---src-pages-blog-[slug]-index-jsx": () => import("./../../../src/pages/blog/[slug]/index.jsx" /* webpackChunkName: "component---src-pages-blog-[slug]-index-jsx" */),
  "component---src-pages-blog-author-[slug]-index-jsx": () => import("./../../../src/pages/blog/author/[slug]/index.jsx" /* webpackChunkName: "component---src-pages-blog-author-[slug]-index-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-careers-index-jsx": () => import("./../../../src/pages/careers/index.jsx" /* webpackChunkName: "component---src-pages-careers-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-events-[slug]-index-jsx": () => import("./../../../src/pages/events/[slug]/index.jsx" /* webpackChunkName: "component---src-pages-events-[slug]-index-jsx" */),
  "component---src-pages-events-index-jsx": () => import("./../../../src/pages/events/index.jsx" /* webpackChunkName: "component---src-pages-events-index-jsx" */),
  "component---src-pages-game-development-jsx": () => import("./../../../src/pages/game-development.jsx" /* webpackChunkName: "component---src-pages-game-development-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-[slug]-index-jsx": () => import("./../../../src/pages/insights/[slug]/index.jsx" /* webpackChunkName: "component---src-pages-insights-[slug]-index-jsx" */),
  "component---src-pages-mobile-app-development-jsx": () => import("./../../../src/pages/mobile-app-development.jsx" /* webpackChunkName: "component---src-pages-mobile-app-development-jsx" */),
  "component---src-pages-news-[slug]-index-jsx": () => import("./../../../src/pages/news/[slug]/index.jsx" /* webpackChunkName: "component---src-pages-news-[slug]-index-jsx" */),
  "component---src-pages-news-index-jsx": () => import("./../../../src/pages/news/index.jsx" /* webpackChunkName: "component---src-pages-news-index-jsx" */),
  "component---src-pages-press-release-[slug]-index-jsx": () => import("./../../../src/pages/press-release/[slug]/index.jsx" /* webpackChunkName: "component---src-pages-press-release-[slug]-index-jsx" */),
  "component---src-pages-press-release-index-jsx": () => import("./../../../src/pages/press-release/index.jsx" /* webpackChunkName: "component---src-pages-press-release-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-services-index-jsx": () => import("./../../../src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-sharepoint-solutions-jsx": () => import("./../../../src/pages/sharepoint-solutions.jsx" /* webpackChunkName: "component---src-pages-sharepoint-solutions-jsx" */),
  "component---src-pages-solutions-cubix-chain-static-index-jsx": () => import("./../../../src/pages/solutions/cubix-chain-static/index.jsx" /* webpackChunkName: "component---src-pages-solutions-cubix-chain-static-index-jsx" */),
  "component---src-pages-solutions-cubix-happyforce-static-index-jsx": () => import("./../../../src/pages/solutions/cubix-happyforce-static/index.jsx" /* webpackChunkName: "component---src-pages-solutions-cubix-happyforce-static-index-jsx" */),
  "component---src-pages-solutions-cubix-shop-static-index-jsx": () => import("./../../../src/pages/solutions/cubix-shop-static/index.jsx" /* webpackChunkName: "component---src-pages-solutions-cubix-shop-static-index-jsx" */),
  "component---src-pages-solutions-dark-theme-new-static-index-jsx": () => import("./../../../src/pages/solutions/dark-theme-new-static/index.jsx" /* webpackChunkName: "component---src-pages-solutions-dark-theme-new-static-index-jsx" */),
  "component---src-pages-solutions-dark-theme-static-index-jsx": () => import("./../../../src/pages/solutions/dark-theme-static/index.jsx" /* webpackChunkName: "component---src-pages-solutions-dark-theme-static-index-jsx" */),
  "component---src-pages-solutions-light-theme-new-static-index-jsx": () => import("./../../../src/pages/solutions/light-theme-new-static/index.jsx" /* webpackChunkName: "component---src-pages-solutions-light-theme-new-static-index-jsx" */),
  "component---src-pages-solutions-light-theme-static-index-jsx": () => import("./../../../src/pages/solutions/light-theme-static/index.jsx" /* webpackChunkName: "component---src-pages-solutions-light-theme-static-index-jsx" */),
  "component---src-pages-terms-of-use-index-jsx": () => import("./../../../src/pages/terms-of-use/index.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-index-jsx" */),
  "component---src-pages-thank-you-index-jsx": () => import("./../../../src/pages/thank-you/index.jsx" /* webpackChunkName: "component---src-pages-thank-you-index-jsx" */),
  "component---src-pages-thankyou-index-js": () => import("./../../../src/pages/thankyou/index.js" /* webpackChunkName: "component---src-pages-thankyou-index-js" */),
  "component---src-pages-university-index-jsx": () => import("./../../../src/pages/university/index.jsx" /* webpackChunkName: "component---src-pages-university-index-jsx" */),
  "component---src-pages-work-index-jsx": () => import("./../../../src/pages/work/index.jsx" /* webpackChunkName: "component---src-pages-work-index-jsx" */),
  "component---src-templates-case-studies-app-app-case-study-jsx": () => import("./../../../src/templates/case-studies/app/app-case-study.jsx" /* webpackChunkName: "component---src-templates-case-studies-app-app-case-study-jsx" */),
  "component---src-templates-case-studies-game-game-case-study-jsx": () => import("./../../../src/templates/case-studies/game/game-case-study.jsx" /* webpackChunkName: "component---src-templates-case-studies-game-game-case-study-jsx" */),
  "component---src-templates-case-studies-web-web-case-study-jsx": () => import("./../../../src/templates/case-studies/web/web-case-study.jsx" /* webpackChunkName: "component---src-templates-case-studies-web-web-case-study-jsx" */),
  "component---src-templates-services-service-jsx": () => import("./../../../src/templates/services/service.jsx" /* webpackChunkName: "component---src-templates-services-service-jsx" */),
  "component---src-templates-solutions-solutions-jsx": () => import("./../../../src/templates/solutions/solutions.jsx" /* webpackChunkName: "component---src-templates-solutions-solutions-jsx" */)
}

