import React from 'react';
import { PricingProvider } from './src/components/ServicePage/MobileAppPage/GetPricing/pricingContext';
import './src/styles/global.css';
// import gsap from 'gsap';
// import { Power4 } from 'gsap';

export const wrapRootElement = ({ element }) => <PricingProvider>{element}</PricingProvider>;

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.pathname === '/blog/' && location.search) {
    return false;
  }

  return true;
};

// export const onRouteUpdate = ({ location }) => {
//   window.scrollTo(0, 0);
// };

// export { onRenderBody } from './gatsby-ssr';
